import type { Document, Folder, PropertyDataType, SignableDocument } from '~/types'
import IconFolderGeneric from '~/assets/images/icon-folder-generic.svg?url'
import IconDocumentContractCreated from '~/assets/images/icon-document-contract-created.svg?url'
import IconDocumentContractFinalized from '~/assets/images/icon-document-contract-finalized.svg?url'
import IconDocumentContractCompleted from '~/assets/images/icon-document-contract-completed.svg?url'
import IconDocumentContractCancelled from '~/assets/images/icon-document-contract-cancelled.svg?url'
import IconDocumentGeneric from '~/assets/images/icon-document-generic.svg?url'
import IconDocumentPdf from '~/assets/images/icon-document-pdf.svg?url'
import IconDocumentExcel from '~/assets/images/icon-document-excel.svg?url'
import IconDocumentImage from '~/assets/images/icon-document-image.svg?url'
import IconDocumentPptx from '~/assets/images/icon-document-pptx.svg?url'
import IconDocumentWord from '~/assets/images/icon-document-word.svg?url'

export const DEFAULT_DOCUMENTS_PER_PAGE = 100
export const DEFAULT_DOCUMENTS_SORT = 'title'

export const DOCUMENT_PII_CONTENTS: App.Internals.Enums.PIIContents[] = [
  'no_pii',
  'contains_pii',
  'contains_sensitive_pii',
]

export const getDocumentStatusCircleClass = (item?: SignableDocument) => {
  switch (item?.contract?.stage) {
    case 'created':
      return 'text-gray-300'
    case 'preview':
      return 'text-gray-700'
    case 'finalized':
      return 'text-yellow-400'
    case 'completed':
      return 'text-green-500'
    case 'cancelled':
      return 'text-orange-500'
    default:
      return undefined
  }
}

export const isFolder = <T extends Record<'_type', string>>(
  item: Folder | Document | T | undefined,
): item is Folder => !!item && item._type === 'folder'
export const isDocument = <T extends Record<'_type', string>>(
  item: Folder | Document | T | undefined,
): item is Document => !!item && item._type === 'document'
export const isMetadataLoaded = (item: Document) => item.terms_and_conditions !== undefined
export const isSignableDocument = (item: Folder | Document | undefined): item is SignableDocument =>
  isDocument(item) && !!item?.contract_id

const getDocumentsFileIconByMime = (mimeType: string | null) => {
  switch (true) {
    case mimeType === 'application/pdf':
      return IconDocumentPdf
    case mimeType?.startsWith('image/'):
      return IconDocumentImage
    case [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ].includes(mimeType ?? ''):
      return IconDocumentWord
    case [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ].includes(mimeType ?? ''):
      return IconDocumentExcel
    case [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint',
    ].includes(mimeType ?? ''):
      return IconDocumentPptx
  }
}

export const getDocumentsRowIcon = (item: Document | Folder) => {
  if (isFolder(item))
    return IconFolderGeneric

  if (isSignableDocument(item)) {
    switch (item.contract?.stage) {
      case 'finalized':
        return IconDocumentContractFinalized
      case 'completed':
        return IconDocumentContractCompleted
      case 'cancelled':
        return IconDocumentContractCancelled
      default:
        return IconDocumentContractCreated
    }
  }
  else if (isDocument(item) && item.file) {
    return getDocumentsFileIconByMime(item.file.mime) || IconDocumentGeneric
  }

  return IconDocumentGeneric
}

export const getDocumentsRowIconOverlayText = (item: Document | Folder) => {
  if (isDocument(item) && item.file && !getDocumentsFileIconByMime(item.file.mime))
    return item.file.filename?.split('.').reverse()[0]
}

export const getDocumentMetaDocumentTypeTitle = (
  t: App.Data.Document.Metadata.DocumentTypeData,
  language: App.Internals.Enums.Language,
) => t.translations?.[language]
|| t.translations?.en
|| Object.entries(t.translations || {}).filter(kv => !!kv[1])[0]?.[1]
|| ''

// Internal helper method for converting attribute data_key to document property key
export const getDocumentMetadataValueKey = (
  type: PropertyDataType,
): `${PropertyDataType}_values` => `${type}_values`

// Internal helper method for converting attribute data_key to document filter entity
export const createDocumentMetadataValueFilterEntity = (
  type: PropertyDataType,
): `document.${PropertyDataType}Values` => `document.${type}Values`

export const canWriteToParentFolder = (item?: Document | Folder) => {
  if (!item)
    return false

  if (isFolder(item))
    return item.parent?.own_permissions?.can_write ?? false

  return item.folder?.own_permissions?.can_write ?? false
}

export const canDeleteInParentFolder = (item?: Document | Folder) => {
  if (!item)
    return false

  if (isFolder(item))
    return !!item.parent?.own_permissions?.can_delete

  return !!item.folder?.own_permissions?.can_delete
}
